import React from "react";
import {Link} from "gatsby";
import Layout from "../components/layout"

const slugs = require('../helpers/slugs');

class Homepage extends React.Component {
    render() {
        const translations = this.props.pageContext.translations;
        const languages = this.props.pageContext.languages;
        const locale = this.props.pageContext.locale;

        slugs.forceHTTPS();

        return (
            <Layout lang={locale} page={this} includeMenu={true} translations={translations}>
                <ul className="lijst-overzicht">
                    {Object.keys(languages).map((locale, index) => {
                        return (
                            <li key={index}>
                                <Link to={slugs.getHomepageSlug(locale)}>{languages[locale]}</Link>
                            </li>
                        )
                    })}
                </ul>
            </Layout>
        );
    }
}

export default Homepage;